export default defineNuxtRouteMiddleware((to, from) => {
  const { loggedIn } = storeToRefs(useAuthStore());
  const { userData } = storeToRefs(useUserStore());

  const previousRoute = from.fullPath || '/dashboard/settings';

  if (!loggedIn.value) {
    return navigateTo('/login');
  }

  if (userData.value.type === 'shipper' || userData.value.isSuperuser) {
    return;
  }

  return navigateTo(previousRoute);
});
